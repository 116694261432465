<template>
    <div class="c-widget--img o-container" :class="{ 'c-widget--img-two' : !singleImg }">
        <figure v-if="singleImg" class="c-fig" :class="`u-align--${data.image_align}`">
            <img class="c-fig__img" :src="data.image_first.sizes.large.src[0]" :alt="data.image_first.alt">
            <figcaption v-if="data.image_caption" class="c-fig__caption">{{ data.image_caption }}</figcaption>
        </figure>
        <div class="c-img-group" v-else>
            <div v-for="(img, i) in imgArr" class="c-img c-img--bg c-img-group__item" :style="img ? imgStyle(img) : ''" :key="i"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PostImage',
        props: {
            data: Object
        },

        methods: {
            imgStyle(img) {
                return {
                    backgroundImage: `url(${ img.sizes.large.src[0] })`
                }
            },
        },

        computed: {
            singleImg () {
                const data = this.data;
                return parseInt(data.image_amount) === 1
            },

            imgArr() {
                return [ this.data.image_first, this.data.image_second ]
            },
        }
    }
</script>

<style scoped lang="scss" src="@/styles/components/_image-group.scss"></style>
<style scoped lang="scss" src="@/styles/components/_image.scss"></style>
<style scoped lang="scss" src="@/styles/components/_fig.scss"></style>
